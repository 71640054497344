@import url(https://fonts.googleapis.com/css2?family=Lobster&display=swap);

html{
-webkit-user-select: none;
-ms-user-select: none;
    user-select: none;
}
img {
  pointer-events: none;
}
.navbar-toggler:focus{
  outline: none;
  box-shadow: none;
}
.navbar-toggler:active{
  background-color: blueviolet;
  color: white;
}
.logo{
  height: 50px;
  margin: 10px;
}
.btn:focus{
  border: none;
  outline: none;
  box-shadow: none;
}
.form-control{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.form-control:focus{
  border: 2px solid blueviolet;
  border-radius: 15px;
  outline: none;
  box-shadow: none;
}
.cbtn{
  color: white;
  background-color: #383CC1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.Gen-Badge{
  margin: 30px;
}
.badge-card{
  
  /* color: white; */
  color: #242B2E;
}
.display-5{
  font-family: 'Lobster', cursive;  

}
.b-body {
  padding: 30px;
}
#Download{
  margin-top: 20px;
}
.shadow{
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.link{
  text-decoration: none;
  color: black;
  position: relative;
  top: 0;
  transition: top ease 0.3s;
}
.link:hover{
  top: -5px;
  transition: all 0.15s cubic-bezier(0.29, 0.73, 0.74, 1.02)
}
footer{
  margin-top: 80px;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='560' preserveAspectRatio='none' viewBox='0 0 1440 560'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1041%26quot%3b)' fill='none'%3e%3cpath d='M 0%2c45 C 96%2c90.4 288%2c258.8 480%2c272 C 672%2c285.2 768%2c129.2 960%2c111 C 1152%2c92.8 1344%2c167 1440%2c181L1440 560L0 560z' fill='rgba(123%2c 81%2c 235%2c 1)'%3e%3c/path%3e%3cpath d='M 0%2c452 C 144%2c434 432%2c347.4 720%2c362 C 1008%2c376.6 1296%2c492.4 1440%2c525L1440 560L0 560z' fill='rgba(123%2c 81%2c 235%2c 1)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1041'%3e%3crect width='1440' height='560' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");

}
#devso{
  padding: 15px;
  text-decoration: none;
  color: #272424;
  font-size: 20px;
  position: relative;
  top: 0;
  transition: top ease 0.3s;
  }
  #devso:hover{
  color: gray;
  top: -5px;
  transition: all 0.15s;
  }
  .rss{
    margin-top: 100px;
    text-align: center;
}

